<template>
  <div class="payment-container px-2 py-3">
    <!-- Logo e título -->
    <div class="py-3 d-flex align-center justify-content-center align-items-center">
      <img
        src="@/assets/logo.png"
        style="max-width: 30px"
        class="mr-3"
        alt="Logo Saúde Social"
      />
      <h1 class="mb-0 page-title">Saúde Social</h1>
    </div>
    <hr class="divider" />

    <!-- Loading state -->
    <div v-if="loading" class="text-center my-4">
      <b-spinner size="lg"></b-spinner>
      <p class="mt-3 loading-text">Carregando informações do pagamento...</p>
      <div>
        <b-skeleton
          class="mt-3"
          height="30px"
          v-for="n in 5"
          :key="n"
        ></b-skeleton>
      </div>
    </div>

    <!-- Error message -->
    <div v-if="error" class="my-4">
      <div class="alert alert-danger py-4">
        <h4 class="alert-heading">Erro ao carregar pagamento</h4>
        <p class="mb-0 mt-2">
          {{ errorMessage }}
        </p>
      </div>
    </div>

    <!-- Payment information -->
    <div v-if="!loading && !error" class="payment-content my-3">
      <div class="payment-info mb-4">
        <div class="info-item">
          <span class="info-label">Código:</span>
          <span class="info-value">{{ paymentCode }}</span>
        </div>
        <div class="info-item">
          <span class="info-label">Pagador:</span>
          <span class="info-value">{{ paymentData.payerName }}</span>
        </div>
        <div class="info-item">
          <span class="info-label">Valor:</span>
          <span class="info-value amount">{{ formatCurrency(paymentData.amount) }}</span>
        </div>
      </div>

      <!-- Payment form -->
      <div class="payment-form">
        <b-tabs content-class="mt-3" nav-class="payment-tabs">
          <b-tab title="Cartão de Crédito" active>
            <b-form @submit.prevent="processPayment" class="card-form">
              <b-form-group label="Número do Cartão">
                <b-input-group>
                  <b-form-input
                    v-model="cardNumber"
                    :formatter="formatCardNumber"
                    placeholder="0000 0000 0000 0000"
                    required
                  ></b-form-input>
                  <b-input-group-append v-if="cardBrand">
                    <b-input-group-text>
                      <i :class="cardBrandIcon"></i>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <div class="row">
                <div class="col-6">
                  <b-form-group label="Validade">
                    <b-form-input
                      v-model="expiryDate"
                      :formatter="formatExpiryDate"
                      placeholder="MM/AA"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group label="CVV">
                    <b-form-input
                      v-model="cvv"
                      type="password"
                      maxlength="4"
                      placeholder="000"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <b-form-group label="Nome no Cartão">
                <b-form-input
                  v-model="cardholderName"
                  placeholder="Nome como está no cartão"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group v-if="selectedPaymentMethod === 'credit'" label="Parcelas">
                <b-form-select
                  v-model="installments"
                  :options="installmentOptions"
                  required
                ></b-form-select>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                class="w-100 mt-4"
                :disabled="loading"
              >
                <b-spinner small v-if="loading"></b-spinner>
                {{ loading ? 'Processando...' : buttonText }}
              </b-button>
            </b-form>
          </b-tab>

          <b-tab title="Cartão de Débito">
            <b-form @submit.prevent="processPayment" class="card-form">
              <b-form-group label="Número do Cartão">
                <b-form-input
                  v-model="cardNumber"
                  :formatter="formatCardNumber"
                  placeholder="0000 0000 0000 0000"
                  required
                ></b-form-input>
              </b-form-group>

              <div class="row">
                <div class="col-6">
                  <b-form-group label="Validade">
                    <b-form-input
                      v-model="expiryDate"
                      :formatter="formatExpiryDate"
                      placeholder="MM/AA"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group label="CVV">
                    <b-form-input
                      v-model="cvv"
                      type="password"
                      maxlength="4"
                      placeholder="000"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <b-form-group label="Nome no Cartão">
                <b-form-input
                  v-model="cardholderName"
                  placeholder="Nome como está no cartão"
                  required
                ></b-form-input>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                class="w-100 mt-4"
                :disabled="loading"
              >
                <b-spinner small v-if="loading"></b-spinner>
                {{ loading ? 'Processando...' : buttonText }}
              </b-button>
            </b-form>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import CaixaRecebimentoAntecipadoLib from '@/libs/CaixaRecebimentoAntecipadoLib';

export default {
  name: 'Payment',
  data: () => ({
    loading: false,
    error: false,
    errorMessage: '',
    selectedPaymentMethod: 'credit',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    cardholderName: '',
    installments: 1,
    paymentCode: '',
    paymentData: {
      payerName: '',
      amount: 0,
      recebimento_id: null,
      entidade_id: null
    },
    cardBrand: '',
  }),

  computed: {
    installmentOptions() {
      const options = [];
      const maxInstallments = 12;
      const amount = this.paymentData.amount;

      for (let i = 1; i <= maxInstallments; i++) {
        const value = amount / i;
        options.push({
          text: `${i}x de ${this.formatCurrency(value)}${i === 1 ? ' à vista' : ' sem juros'}`,
          value: i
        });
      }

      return options;
    },

    buttonText() {
      return 'Pagar ' + this.formatCurrency(this.paymentData.amount);
    },

    cardBrandIcon() {
      const brands = {
        visa: 'fab fa-cc-visa',
        mastercard: 'fab fa-cc-mastercard',
        amex: 'fab fa-cc-amex',
        discover: 'fab fa-cc-discover',
        diners: 'fab fa-cc-diners-club',
        jcb: 'fab fa-cc-jcb',
        elo: 'fa fa-credit-card', // Elo não tem ícone específico no Font Awesome
        hipercard: 'fa fa-credit-card', // Hipercard não tem ícone específico no Font Awesome
      };
      return brands[this.cardBrand] || 'fa fa-credit-card';
    }
  },

  created() {
    // Get payment data from route params
    const { recebimento_id, entidade_id, amount, payerName } = this.$route.params;
    if (recebimento_id && entidade_id) {
      this.paymentData = {
        recebimento_id,
        entidade_id,
        amount: parseFloat(amount),
        payerName
      };
      this.paymentCode = `ANT${recebimento_id}`;
    } else {
      this.error = true;
      this.errorMessage = 'Dados do pagamento não encontrados';
    }
  },

  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value);
    },

    formatCardNumber(value) {
      if (!value) return '';
      value = value.replace(/\D/g, '');
      if (value.length > 0) {
        value = value.match(/.{1,4}/g).join(' ');
        // Identifica a bandeira do cartão quando o número é alterado
        this.cardBrand = this.identifyCardBrand(value);
      }
      return value;
    },

    formatExpiryDate(value) {
      if (!value) return '';
      value = value.replace(/\D/g, '');
      if (value.length > 2) {
        value = value.substring(0, 2) + '/' + value.substring(2, 4);
      }
      return value;
    },

    getCardExpirationParts() {
      const [month, year] = this.expiryDate.split('/');
      return {
        month: month.padStart(2, '0'),
        year: `20${year}`
      };
    },

    async processPayment() {
      this.loading = true;
      this.error = false;
      
      try {
        const { month, year } = this.getCardExpirationParts();
        
        const paymentData = {
          recebimento_id: this.paymentData.recebimento_id,
          entidade_id: this.paymentData.entidade_id,
          cardType: this.selectedPaymentMethod.toUpperCase() === 'CREDIT' ? 'CREDIT' : 'DEBIT',
          cardToken: this.cardNumber.replace(/\s/g, ''), // Remove spaces
          cardholderName: this.cardholderName,
          expirationMonth: month,
          expirationYear: year,
          securityCode: this.cvv,
          installments: this.selectedPaymentMethod === 'credit' ? this.installments : 1
        };

        const response = await CaixaRecebimentoAntecipadoLib.processCardPayment(paymentData);

        if (response.success) {
          this.$router.push({
            name: 'payment-success',
            params: {
              paymentId: response.payment.payment_id,
              amount: this.paymentData.amount,
              installments: paymentData.installments
            }
          });
        } else {
          throw new Error(response.message || 'Erro ao processar pagamento');
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = error.response?.data?.message || error.message || 'Erro ao processar pagamento';
      } finally {
        this.loading = false;
      }
    },

    identifyCardBrand(number) {
      // Remove espaços e caracteres não numéricos
      number = number.replace(/\D/g, '');
      
      // Regras para identificação das bandeiras
      const patterns = {
        visa: /^4/,
        mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
        amex: /^3[47]/,
        discover: /^6(?:011|5[0-9]{2})/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
        elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|65091[0-9]|650920)|(65165[2-9]|6516[6-7][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/,
        hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/
      };

      for (let [brand, pattern] of Object.entries(patterns)) {
        if (pattern.test(number)) {
          return brand;
        }
      }
      
      return '';
    },
  }
}
</script>

<style scoped>
.payment-container {
  min-height: 100vh;
  background-color: #f8f9fa;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2c3e50;
}

.divider {
  border-top: 1px solid #e9ecef;
  margin: 1rem 0;
}

.loading-text {
  color: #6c757d;
  font-size: 1.1rem;
}

.payment-content {
  max-width: 600px;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-info {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 1rem;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e9ecef;
}

.info-item:last-child {
  border-bottom: none;
}

.info-label {
  font-weight: 600;
  color: #6c757d;
}

.info-value {
  color: #2c3e50;
}

.info-value.amount {
  font-size: 1.2rem;
  font-weight: 600;
  color: #28a745;
}

.payment-tabs {
  border-bottom: 2px solid #e9ecef;
}

.nav-tabs .nav-link {
  color: #6c757d;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
}

.nav-tabs .nav-link.active {
  color: #2c3e50;
  font-weight: 600;
  border-bottom: 2px solid #007bff;
}

.card-form {
  padding: 1rem 0;
}

@media (max-width: 576px) {
  .payment-content {
    padding: 1rem;
    margin: 0.5rem;
  }

  .payment-info {
    padding: 0.75rem;
  }

  .page-title {
    font-size: 1.25rem;
  }
}

.input-group-text {
  background-color: transparent;
}

.fa-cc-visa {
  color: #1a1f71;
}

.fa-cc-mastercard {
  color: #eb001b;
}

.fa-cc-amex {
  color: #2e77bc;
}

.fa-cc-discover {
  color: #ff6000;
}

.fa-cc-diners-club {
  color: #0079be;
}

.fa-cc-jcb {
  color: #0b4ea2;
}

.fa-credit-card {
  color: #6c757d;
}

.alert {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 4px;
}

.alert-danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
</style> 