import axios from "@/utils/axios.js";

const createAntecipado = async (data) => {
  try {
    const response = await axios.post('/api/caixa-recebimento-antecipado/create', data);
    return response.data;
  } catch (error) {
    console.error('Error creating anticipated payment:', error);
    throw error;
  }
};

const processCardPayment = async (data) => {
  try {
    const response = await axios.post('/api/caixa-recebimento-antecipado/process-card-payment', data);
    return response.data;
  } catch (error) {
    console.error('Error processing card payment:', error);
    throw error;
  }
};

export default {
  createAntecipado,
  processCardPayment
}; 